.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.snake-container {
  width: 100vw;
  height: 80vh;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.info-container {
  height: 20vh;
  overflow: hidden;
}

.grid {
  width: 500px;
  height: 500px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid-item {
  outline: 1px solid rgb(130, 20, 11);
  width: 50px;
  height: 50px;
}

.snake {
  background-color: blue;
  width: 50px;
  height: 50px;
}

.head {
  width: 50px;
  height: 50px;
}

.food {
  width: 50px;
  height: 50px;
}

h1 {
  text-align: center;
  font-size: 2rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  text-align: center;
}
